import axios from "axios";

const api = axios.create({
  baseURL: '/api/',
});

api.interceptors.response.use(null, function(error) {
  if (error.response.status === 500) {
    if (error.response.data && error.response.data.message) {
      alert(error.response.data.message);
    } else {
      alert("An error occurred.");
    }
  }

  return Promise.reject(error);
});

export default api;
