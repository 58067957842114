import React, {useState, useEffect, Fragment} from "react";
import { render } from "react-dom";
import api from "./api";

const SignUpForm = ({onSubmit, onCancel}) => {
  const [name, setName] = useState("");
  const [ward, setWard] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit({host: name, ward});
  };

  const handleCancel = e => {
    e.preventDefault();
    onCancel();
  }

  return (
    <form onSubmit={handleFormSubmit} className="form">
      <div className="form-group">
        <label>Name</label>
        <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} placeholder="Name" />
      </div>
      <div className="form-group">
        <label>Ward</label>
        <input type="text" className="form-control" value={ward} onChange={e => setWard(e.target.value)} placeholder="Ward" />
      </div>
      <button type="submit" className="btn btn-primary btn-sm">Sign Up</button>
      &nbsp;
      <button className="btn btn-secondary btn-sm" onClick={handleCancel}>Cancel</button>
    </form>
  );
};


const SignUpCell = ({date, onSignUp}) => {
  const [started, setStarted] = useState(false);

  const handleSignUpClick = event => {
    event.preventDefault();
    setStarted(true);
  };

  const handleFormCancelled = event => {
    setStarted(false);
  };

  const handleFormSubmit = event => {
    const id = date.id;
    onSignUp({...event, id});
    setStarted(false);
  };

  if (started) {
    return <td><SignUpForm onSubmit={handleFormSubmit} onCancel={handleFormCancelled} /></td>;
  }

  return (<td><a href="#" role="button" onClick={handleSignUpClick}>Sign Up</a></td>);
};

const FullCell = ({date, onCancel}) => {
  const [started, setStarted] = useState(false);

  const handleCancelClick = e => {
    e.preventDefault();
    setStarted(true);
  };

  const handleConfirmClick = e => {
    e.preventDefault();
    onCancel(date.id);
  };

  const handleAbortClick = e => {
    e.preventDefault();
    setStarted(false);
  };

  if (!started) {
    return (<td>{date.host} {date.ward && <Fragment>- {date.ward}</Fragment>} (<a href="#" role="button" onClick={handleCancelClick}>Cancel</a>)</td>);
  }

  return (
    <td>
      Are you sure?
      <form>
        <button className="btn btn-danger btn-sm" onClick={handleConfirmClick}>Cancel the dinner appointment.</button>
        &nbsp;
        <button className="btn btn-secondary btn-sm" onClick={handleAbortClick}>Leave it with {date.host}.</button>
      </form>
    </td>
  );
};

const SignUpGrid = props => {
  const [profileId, setProfileId] = useState("");
  const [days, setDays] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  if (props.profileId !== profileId) {
    console.log("profile ID changed");
    setProfileId(props.profileId);
    setLoaded(false);
    setLoading(false);
    setDays([]);
  }

  function handleSignUp(e) {
    api.post(`/profiles/${props.profileId}/events/${e.id}/reservation`, e)
      .then(function (response) {
        setLoaded(false);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleCancel(id) {
    api.post(`/profiles/${props.profileId}/events/${id}/cancellation`)
      .then(function (response) {
        setLoaded(false);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(
    () => {
      if (!loading && !loaded) {
        api.get(`/profiles/${profileId}/events/`)
          .then(function (response) {
            setDays(response.data);
            setLoaded(true);
          })
          .catch(function (error) {
            console.log("Failed to load items");
          }); 
        setLoading(true);
      }
    },
    [profileId, api, days, loaded, loading]
  );

  if (!loaded) {
    return <p>Loading...</p>;
  }

  const rows = days.map(d => 
    <tr key={d.id}>
      <td>{d.day}<br />{d.date}</td>
      {d.host ? (<FullCell date={d} onCancel={handleCancel} />) : (<SignUpCell date={d} onSignUp={handleSignUp} />)}
    </tr>
  );

  return (
    <table className="table" style={{tableLayout: "fixed"}}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Host</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

const SignUpPane = props => {
  const [profile, setProfile] = useState(null);

  function handleChooseProfile(p) {
    setProfile(p);
  }

  if (!profile)
  {
    return <ProfileMenu profile={profile} onChooseProfile={handleChooseProfile} />;
  }

  return (
    <div>
      <ProfileMenu onChooseProfile={handleChooseProfile} profile={profile} />
      <div className="border">
        <h2 className="m-3">{profile.name}</h2>
        <p className="m-3">{profile.description}</p>
        <SignUpGrid profileId={profile.id} />
      </div>
    </div>
  );
}


const ProfileMenu = props => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(
    () => {
      if (!loading && !loaded) {
        api.get(`/profiles/`)
          .then(function (response) {
            console.log("Loaded events", response);
            setProfiles(response.data);
            setLoaded(true);
          })
          .catch(function (error) {
            console.log("Failed to load items");
          });
        setLoading(true);
      }
    },
    [api, profiles, loaded, loading]
  );

  if (!loaded) {
    return <p>Loading...</p>;
  }

  function handleProfileButton(e, profile) {
    e.preventDefault();
    if (props.onChooseProfile) {
      props.onChooseProfile(profile);
    }
  }

  const rows = profiles.map(p =>
    <li key={p.id} className="nav-item">
      <a className={p.id === props.profile?.id ? "nav-link active" : "nav-link"} href="#" onClick={e=>handleProfileButton(e, p)}>
        {p.name}
      </a>
    </li>
  );

  return <div>
    {props.profile !== null ||
      <div className="mb-4">Choose a missionary companionship to get started.</div>
    }
    <ul className="nav nav-tabs" role="tablist">{rows}</ul>
  </div>;
}


const Root = function() {
  return (
    <div className="container">
      <header className="mt-4">
        <h1>Missionary Meals <small>Medicine Hat</small></h1>
      </header>
      <SignUpPane />
    </div>
  );
}

render(<Root />, document.getElementById("root"));
